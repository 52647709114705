import Vue from 'vue'
import Vuex from 'vuex'
import { getTokenFromCookie } from "@/plugins/axios";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    loading: getTokenFromCookie() != null,
    user: null
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_USER(state, user) {
      state.user = user
    },
  },
  actions: {
    loadUser(context) {
      return Vue.axios.get(process.env.NODE_ENV !== 'production' ? 'http://localhost:8000/api/v1/user' : 'https://id.onegaming.group/api/v1/user')
          .then(response => {
            context.commit('SET_USER', response.data)
          })
          .finally(() => {
            context.commit('SET_LOADING', false)
          })
    },
    logout(context) {
      return Vue.axios.get(process.env.NODE_ENV !== 'production' ? 'http://localhost:8001/v1/auth/logout' : 'https://api.mcone.eu/v1/auth/logout')
          .then(() => {
            context.commit('SET_USER', null)
          })
    }
  },
  modules: {
  }
})

export default store;
