import Vue from 'vue'
import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

axios.defaults.baseURL = process.env.NODE_ENV !== 'production' ? 'http://localhost:8001/v1' : 'https://api.mcone.eu/v1'
createAuthRefreshInterceptor(axios, failedRequest => axios.get('/auth/refresh_token', {
    withCredentials: true
}).then(() => {
    failedRequest.response.config.headers['Authorization'] = `Bearer ${getTokenFromCookie()}`
    return Promise.resolve()
}).catch(error => {
    if (error.response && error.response.status === 401) {
        Vue.$store.dispatch('logout')
        Vue.$store.commit('SET_LOADING', false)
    } else {
        console.error(error)
    }
}))
axios.interceptors.request.use(request => {
    request.headers['Authorization'] = `Bearer ${getTokenFromCookie()}`;
    return request;
});
Vue.axios = axios;
Object.defineProperties(Vue.prototype, {
    axios: {
        get() {
            return axios
        }
    }
})

const getTokenFromCookie = () => {
    var cookie = document.cookie.split('; ').find(row => row.startsWith('token'))
    return cookie ? cookie.split('=')[1] : null
}

export {
    getTokenFromCookie
};
