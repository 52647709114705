<template>
    <div class="home">
        <div class="header-wrapper d-flex justify-content-center align-items-center">
            <b-container class="header-container d-flex flex-wrap align-items-center justify-content-center">
                <div class="d-none d-lg-block mr-lg-auto text-center">
                    <!--<div class="santa-wrapper">
                        <santa-head />
                    </div>-->
                    <img src="../assets/img/mc1_logo_light.svg" class="header-logo" alt="MC ONE Logo"/>
                    <h2 class="m-0">
                        <b-badge variant="light">BETA</b-badge>
                    </h2>
                    <h1 class="text-light header-title font-weight-bold m-0">
                        MC ONE
                    </h1>
                    <h2>
                        <small class="text-muted">Dein Nummer 1 Netzwerk</small>
                    </h2>
                </div>
                <div class="card text-center header-info-card bg-transparent border-0 shadow">
                    <div class="card-body p-4 header-info-card-body text-light">
                        <h2 class="mb-3">In Entwicklung!</h2>
                        <p>
                            Das bedeutet, dass unser Netzwerk zum jetzigen Zeitpunkt noch nicht zum Spielen geeignet
                            ist.<br>
                            Falls Du jedoch einige unserer Funktionen schon testen willst und ein paar Bugs in Kauf nimmst,
                            freuen wir uns auf Deinen Besuch!
                        </p>
                    </div>
                    <div class="card-footer border-0 header-info-card-footer p-4 align-items-start text-light">
                        <h5 class="text-dark mb-3">
                            {{ user ? `👋 Hi ${$store.state.user.name}!` : 'Wir suchen Teammitglieder!' }}<br>
                            <small class="font-italic text-muted">Bewirb dich jetzt als <b-badge variant="info">Developer</b-badge> oder <b-badge variant="warning">Builder</b-badge></small>
                        </h5>
                        <b-button to="/apply" variant="outline-info">Jetzt Bewerben</b-button>
<!--                        <h5 style="line-height: 1.3"><small>
                          Wir alle hier bei MC ONE wünschen dir ein frohes neues Jahr 2021!
                        </small></h5>-->
                    </div>
                </div>
            </b-container>
        </div>

        <div class="wrapper">
            <!--<section id="blog">
              <div class="container">
                <div class="section-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                  <h1 class="display-4">Blog</h1>
                  <p class="lead">Quickly build an effective pricing table for your potential customers with this
                    Bootstrap example. It’s built with default Bootstrap components and utilities with little
                    customization.</p>
                </div>

                <div class="row mb-2">
                  <div class="col-md-6">
                    <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                      <div class="col p-4 d-flex flex-column position-static">
                        <strong class="d-inline-block mb-2 text-primary">World</strong>
                        <h3 class="mb-0">Featured post</h3>
                        <div class="mb-1 text-muted">Nov 12</div>
                        <p class="card-text mb-auto">This is a wider card with supporting text below as a
                          natural lead-in to additional content.</p>
                        <a href="#" class="stretched-link">Continue reading</a>
                      </div>
                      <div class="col-auto d-none d-lg-block">
                        <svg class="bd-placeholder-img" width="200" height="250"
                             xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice"
                             focusable="false" role="img" aria-label="Placeholder: Thumbnail">
                          <title>Placeholder</title>
                          <rect width="100%" height="100%" fill="#55595c"></rect>
                          <text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                      <div class="col p-4 d-flex flex-column position-static">
                        <strong class="d-inline-block mb-2 text-success">Design</strong>
                        <h3 class="mb-0">Post title</h3>
                        <div class="mb-1 text-muted">Nov 11</div>
                        <p class="mb-auto">This is a wider card with supporting text below as a natural
                          lead-in
                          to additional content.</p>
                        <a href="#" class="stretched-link">Continue reading</a>
                      </div>
                      <div class="col-auto d-none d-lg-block">
                        <svg class="bd-placeholder-img" width="200" height="250"
                             xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice"
                             focusable="false" role="img" aria-label="Placeholder: Thumbnail">
                          <title>Placeholder</title>
                          <rect width="100%" height="100%" fill="#55595c"></rect>
                          <text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="gamemodes" class="bg-light">
              <div class="container">
                <div class="section-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                  <h1 class="display-4">Spielmodi</h1>
                  <p class="lead">Quickly build an effective pricing table for your potential customers with this
                    Bootstrap example. It’s built with default Bootstrap components and utilities with little
                    customization.</p>
                </div>

                <div class="card-deck mb-3 text-center">
                  <div class="card mb-4 shadow-sm">
                    <div class="card-header">
                      <h4 class="my-0 font-weight-normal">Free</h4>
                    </div>
                    <div class="card-body">
                      <h1 class="card-title pricing-card-title">$0
                        <small class="text-muted">/ mo</small>
                      </h1>
                      <ul class="list-unstyled mt-3 mb-4">
                        <li>10 users included</li>
                        <li>2 GB of storage</li>
                        <li>Email support</li>
                        <li>Help center access</li>
                      </ul>
                      <button type="button" class="btn btn-lg btn-block btn-outline-primary">Sign up for free
                      </button>
                    </div>
                  </div>
                  <div class="card mb-4 shadow-sm">
                    <div class="card-header">
                      <h4 class="my-0 font-weight-normal">Pro</h4>
                    </div>
                    <div class="card-body">
                      <h1 class="card-title pricing-card-title">$15
                        <small class="text-muted">/ mo</small>
                      </h1>
                      <ul class="list-unstyled mt-3 mb-4">
                        <li>20 users included</li>
                        <li>10 GB of storage</li>
                        <li>Priority email support</li>
                        <li>Help center access</li>
                      </ul>
                      <button type="button" class="btn btn-lg btn-block btn-primary">Get started</button>
                    </div>
                  </div>
                  <div class="card mb-4 shadow-sm">
                    <div class="card-header">
                      <h4 class="my-0 font-weight-normal">Enterprise</h4>
                    </div>
                    <div class="card-body">
                      <h1 class="card-title pricing-card-title">$29
                        <small class="text-muted">/ mo</small>
                      </h1>
                      <ul class="list-unstyled mt-3 mb-4">
                        <li>30 users included</li>
                        <li>15 GB of storage</li>
                        <li>Phone and email support</li>
                        <li>Help center access</li>
                      </ul>
                      <button type="button" class="btn btn-lg btn-block btn-primary">Contact us</button>
                    </div>
                  </div>
                </div>
              </div>
            </section>-->

            <section id="ts-discord">
                <div class="container">
                    <div class="section-header py-3 pt-md-5 pb-md-4 mx-auto text-center">
                        <h1 class="display-4">Teamspeak & Discord</h1>
                        <p class="lead">
                            Hier kannst du dich mit Freunden unterhalten, Musik hören oder einfach Chatten.
                            Als verifizierter MC ONE Spieler erhälst du sogar ein paar Vorteile.
                            Falls du Support brauchst, helfen wir dir gerne persönlich auf Teamspeak oder im Chat auf
                            Discord.
                        </p>
                    </div>

                    <div class="row mb-2">
                        <div class="col-md-6 mb-3 mb-md-0">
                            <b-card header-bg-variant="dark" header-class="d-flex" body-class="p-0" style="border-width: 1px">
                                <template v-slot:header>
                                    <h5 class="mb-0 text-light">
                                        <fai :icon="['fab', 'teamspeak']" class="mr-2"/>
                                        Teamspeak
                                    </h5>
                                </template>
                                <div v-if="teamspeakData === null" v-html="voiceCardLoading"></div>
                                <div v-else id="tsviewer" class="voice-info ts3index-viewer"
                                     v-html="teamspeakData"></div>
                                <template v-slot:footer style="border-width: 1px">
                                    <a href="ts3server://ts.mcone.eu" class="card-link">
                                        <fai :icon="['fas', 'external-link-square-alt']"/>
                                        Zu Teamspeak Verbinden
                                    </a>
                                    <a href="#" class="card-link float-right" v-on:click.prevent="updateTeamspeak()">
                                        <fai :icon="['fas', 'sync-alt']"/>
                                    </a>
                                </template>
                            </b-card>
                        </div>
                        <div class="col-md-6">
                            <b-card header-bg-variant="dark" footer-bg-variant="light" body-class="p-0" style="border-width: 1px">
                                <template v-slot:header>
                                    <h5 class="mb-0 text-light">
                                        <fai :icon="['fab', 'discord']" class="mr-2"/>
                                        Discord
                                    </h5>
                                </template>
                                <div v-if="discordData === null" v-html="voiceCardLoading"></div>
                                <div v-else id="dcviewer" class="voice-info p-3">
                                    <h5><small class="text-muted">Sprachchannel</small></h5>
                                    <span v-for="channel in this.discordData.channels" v-bind:key="channel.id">{{ channel.name }}<br></span>

                                    <h5 class="mt-2"><small class="text-muted">Online User</small></h5>
                                    <div v-for="member in this.discordData.members" v-bind:key="member.id">
                                        <img alt="" :src="member.avatar_url" height="18" width="18" class="dc-img mr-1">
                                        {{ member.username }}
                                        <br>
                                    </div>
                                </div>
                                <template v-slot:footer>
                                    <a :href="discordData != null ? discordData.instant_invite : 'https://discord.gg/b6BXpdt'"
                                       target="_blank" rel="noopener" class="card-link">
                                        <fai :icon="['fas', 'external-link-square-alt']"/>
                                        Zu Discord Verbinden
                                    </a>
                                    <a href="#" class="card-link float-right" v-on:click.prevent="updateDiscord()">
                                        <fai :icon="['fas', 'sync-alt']"/>
                                    </a>
                                </template>
                            </b-card>
                        </div>
                    </div>
                </div>
            </section>

            <section id="partner">
              <b-container>
                <div class="section-header py-3 pt-md-5 pb-md-4 mx-auto text-center">
                  <h1 class="display-4">Partner</h1>

                  <div v-for="partner in partners" :key="partner.name" class="d-flex justify-content-center">
                    <a :href="partner.url" target="_blank" rel="noopener" class="partner-link d-flex p-2 rounded-lg border-secondary">
                      <img :src="partner.img" alt="Create Silent" height="40" class="mr-3" width="40">
                      <h2 :style="{color: partner.color}">{{ partner.name }}</h2>
                    </a>
                  </div>
                </div>
              </b-container>
            </section>
        </div>
    </div>
</template>

<script>
//import SantaHat from '@/components/SantaHat'

export default {
    name: 'home',
    /*components: {
      'santa-head': SantaHat,
    },*/
    data: function () {
        return {
            voiceCardLoading: '<div class="text-center d-flex align-items-center justify-content-center" style="height: 200px"><div class="spinner-border text-secondary" style="width: 3rem; height: 3rem;" ></div></div>',
            teamspeakData: null,
            discordData: null,
            partners: [
              {
                img: require('../assets/img/createsilent_logo.svg'),
                name: 'createsilent',
                url: 'https://createsilent.com/',
                color: '#ff933b'
              }
            ]
        }
    },
    methods: {
        updateTeamspeak() {
            this.teamspeakData = null
            this.axios.get(process.env.NODE_ENV === 'production' ? 'https://api.mcone.eu/v1/teamspeak' : 'http://localhost:8001/v1/teamspeak')
                .then(response => {
                    this.teamspeakData = response.data;
                })
                .catch(error => {
                    this.teamspeakData = 'Error loading TS data: ' + JSON.stringify(error)
                })
        },
        updateDiscord() {
            this.discordData = null
            this.axios.get('https://discordapp.com/api/guilds/194099676537028608/widget.json')
                .then(response => {
                    this.discordData = response.data;
                })
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    mounted: function () {
        this.updateTeamspeak()
        this.updateDiscord()
    }
}
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import url('https://ts3index.com/viewer/style.php?elemid=tsviewer');
    @import url('https://ts3index.com/style/country.css');

    .header-wrapper {
        background: linear-gradient(
                        to right,
                        rgba(0, 0, 0, 0.8),
                        rgba(7, 142, 140, 0.4)
        ), linear-gradient(
                        to bottom,
                        rgba(38, 123, 163, 0.4),
                        rgba(56, 153, 77, 0.3)
        ), url('../assets/img/lobby.png') border-box center/cover no-repeat fixed;
    }

    .santa-wrapper {
        position: relative;
        bottom: 110px;
        right: 85px;
        transform: rotate(4deg);
    }

    .chistmas-card {
        background-color: rgba(120, 58, 174, 0.31);
    }

    .card-body, .card-footer {
        border-width: 1px !important;
        backdrop-filter: blur(10px)
    }

    .voice-info {
        height: 450px;
        overflow: auto;
    }

    #tsviewer {
        padding: 10px;
    }

    #dcviewer {
        font-size: 15px;
    }

    .dc-img {
        border-radius: 50%;
    }

    .partner-link {
        filter: grayscale(1);
        transition: filter .2s ease;

        &:hover {
            filter: none;
        }
    }
</style>
