<template>
    <div id="app">
<!--        <snowflakes class="position-absolute w-100" :style="{height: $route.name !== 'Regelwerk' ? '100%' : '50%'}"></snowflakes>-->
        <b-navbar id="navbar-main" toggleable="md"
                  :class="['navbar', 'navbar-expand-lg', 'navbar-dark', 'bg-dark', 'scrolled-dark', 'fixed-top', navTransparent && !['Cape'].includes($route.name) ? '' : 'scrolled']">
            <div class="container">
                <div class="navbar-brand-wrapper">
                    <router-link class="my-0 d-flex mr-md-auto navbar-brand text-light" to="/">
                        <div class="img mr-2">
                            <fai :icon="['fas', 'mc1-out']" size="2x" />
                        </div>
                        <div class="text" style="line-height: 1">
                            <span class="font-univia">MC ONE</span>
                        </div>
                    </router-link>
                    <small class="text-light p-1" style="position: absolute; transform: translate(calc(50% - 4px), calc(-50% - 13px))">
                        by <span class="font-univia text-light">OneGaming</span>
                    </small>
                </div>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" class="py-2" is-nav>
                    <ul class="navbar-nav ml-auto d-inline-block d-md-flex text-center w-100">
                        <li class="server-badge-group ml-auto d-none d-md-block px-1">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text server-badge-label text-light border-0"
                                         id="mc-ip-label">
                                        <img src="@/assets/img/minecraft-logo.png" alt="IP" height="22" width="22">
                                    </div>
                                </div>
                                <input id="mc-ip-copy" ref="ipCopy" type="text" class="form-control server-badge code"
                                       style="width: 95px !important;height: 40px"
                                       value="mcone.eu" aria-label="ip" aria-describedby="mc-ip-label" disabled>
                                <b-tooltip :disabled="true" ref="ipCopyTooltip" target="mc-ip-copy">Server-IP kopiert!
                                </b-tooltip>
                                <div class="input-group-append">
                                    <div class="input-group-text server-badge-icon border-0">
                                        <fai :icon="['far', 'copy']" v-on:click="copyIpToClipboard()"/>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item d-none d-md-block px-1">
                            <a class="btn btn-dark social-iconbar-icon" href="ts3server://ts.mcone.eu">
                                <fai :icon="['fab', 'teamspeak']"/>
                            </a>
                        </li>
                        <li class="nav-item d-none d-md-block px-1">
                            <a class="btn btn-dark social-iconbar-icon" href="https://discord.mcone.eu" target="_blank"
                               rel="noopener">
                                <fai :icon="['fab', 'discord']"/>
                            </a>
                        </li>
                        <li class="my-2 my-md-0 mr-md-2 p-0">
                            <router-link class="p-2 text-light d-inline-block" to="/apply"
                                         style="vertical-align: center">Bewerben
                            </router-link>
                            <router-link class="p-2 text-light d-inline-block" to="/story"
                                         style="vertical-align: center">Story
                            </router-link>
                        </li>
                        <li class="nav-item d-inline-block">
                            <div v-if="loading" class="account-img bg-loading rounded" style="height: 36px; width: 36px"></div>
                            <account-menu v-else-if="user"></account-menu>
                            <b-button v-else rel="noopener" :href="loginLink" id="btn-login" class="btn btn-light">
                                Anmelden
                            </b-button>
                        </li>
                    </ul>
                </b-collapse>
            </div>
        </b-navbar>

        <transition name="fade" mode="out-in" @before-enter="closeNavIfCollapsed" @after-leave="$root.$emit('triggerScroll')">
            <router-view/>
        </transition>

        <div class="container">
            <footer class="pt-4 my-4 mt-md-5 pt-md-5 border-top">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-4 mb-3 mb-lg-0">
                        <h5>Über uns</h5>
                        <span class="text-secondary">
                            MC ONE ist als deutscher Minecraft-Server Teil der Multigaming Community OneGaming.
                            Auf unseren Minecraftservern findest du von uns selbst konzipierte und entwickelte Minigames.
                            Wir freuen uns auf deinen Besuch!
                        </span>
                    </div>
                    <div class="col-6 col-md-3 col-lg mb-3 mb-md-0">
                        <h5>Links</h5>
                        <ul class="list-unstyled text-small">
                            <li>
                                <a class="text-secondary" href="" v-on:click.prevent>Status
                                    <b-badge>Bald</b-badge>
                                </a>
                            </li>
                            <li>
                                <a class="text-secondary" href="" v-on:click.prevent>Userfinder
                                    <b-badge>Bald</b-badge>
                                </a>
                            </li>
                            <li>
                                <a class="text-secondary" href="" v-on:click.prevent>Support
                                    <b-badge>Bald</b-badge>
                                </a>
                            </li>
                            <li>
                                <router-link class="text-secondary" to="/team">Unser Team</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6 col-md-3 col-lg mb-3 mb-md-0">
                        <h5>Soziale Medien</h5>
                        <ul class="list-unstyled text-small">
                            <li>
                                <a class="text-secondary" target="_blank" rel="noopener" href="https://twitter.com/mconeeu">Twitter</a>
                            </li>
                            <li>
                                <a class="text-secondary" target="_blank" rel="noopener" href="https://www.facebook.com/mconeeu/">Facebook</a>
                            </li>
                            <li>
                                <a class="text-secondary" target="_blank" rel="noopener" href="https://www.instagram.com/mconeeu/">Instagram</a>
                            </li>
                            <li>
                                <a class="text-secondary" target="_blank" rel="noopener" href="https://www.youtube.com/channel/UCG8upNAMiAchDxiFoe8dbaQ">YouTube</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6 col-md-3 col-lg">
                        <h5>OneGaming</h5>
                        <ul class="list-unstyled text-small">
                            <li><a class="text-secondary" href="" v-on:click.prevent>Onegaming
                                <b-badge>Bald</b-badge>
                            </a></li>
                            <li><a class="text-secondary" href="" v-on:click.prevent>Account
                                <b-badge>Bald</b-badge>
                            </a></li>
                            <li><a class="text-secondary" href="" v-on:click.prevent>Developer
                                <b-badge>Bald</b-badge>
                            </a></li>
                        </ul>
                    </div>
                    <div class="col-6 col-md-3 col-lg">
                        <h5>Rechtlichtes & Regeln</h5>
                        <ul class="list-unstyled text-small">
                            <li><router-link class="text-secondary" to="/imprint">Impressum</router-link></li>
                            <li><router-link class="text-secondary" to="/privacy">Datenschutz</router-link></li>
                            <li><router-link class="text-secondary" to="/rules">Regelwerk</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="text-center text-secondary mt-3">
                    Made with &hearts; by the MCONE Team.
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
    import AccountMenu from '@/components/AccountMenu'

    export default {
        components: {
            'account-menu': AccountMenu
        },
        data() {
            return {
                navTransparent: true,
                navCollapsed: false,
                loginLink: `${process.env.NODE_ENV !== 'production' ? 'http://localhost:8001/v1/auth/authorize' : 'https://api.mcone.eu/v1/auth/authorize'}?redirect_uri=${encodeURIComponent(window.location.href)}`
            }
        },
        methods: {
            handleScroll() {
                this.navTransparent = window.pageYOffset < (window.innerWidth > 767.98 ? 120 : 20)
                this.closeNavIfCollapsed()
            },
            closeNavIfCollapsed() {
                if (this.navCollapsed) {
                    this.$root.$emit('bv::toggle::collapse', 'nav-collapse')
                }
            },
            copyIpToClipboard() {
                let $e = this.$refs.ipCopy
                let $dis = false

                if ($e.hasAttribute("disabled")) {
                    $e.removeAttribute("disabled")
                    $dis = true
                }

                $e.select()
                document.execCommand("copy")

                if ($dis) {
                    $e.disabled = true
                }

                this.$refs.ipCopyTooltip.$emit('open')
                setTimeout(function () {
                    this.$refs.ipCopyTooltip.$emit('close')
                }.bind(this), 2000)
            }
        },
        computed: {
            user() {
                return this.$store.state.user
            },
            loading() {
                return this.$store.state.loading
            }
        },
        created() {
            window.addEventListener('scroll', this.handleScroll)
        },
        mounted() {
            this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
                this.navCollapsed = isJustShown;

                if (collapseId === 'nav-collapse' && window.pageYOffset < (window.innerWidth > 767.98 ? 120 : 20)) {
                    this.navTransparent = !isJustShown;
                }
            })
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll)
        }
    }
</script>


<style>
    .social-iconbar-icon {
        padding: 8px 10px 8px 10px !important;
    }

    .header-container {
      z-index: 200;
      position: relative;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.15s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0
    }
</style>
