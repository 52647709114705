import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/vue-fontawesome'
import './plugins/axios'
import './plugins/sentry'
import router from './router'
import store from './store'
import App from './App.vue'

import '@/assets/scss/app.scss'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  async mounted() {
    store.dispatch('loadUser')
  }
}).$mount('#app')
