import Vue from 'vue'
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://79284b1670924474b91e331820b9f37a@o267551.ingest.sentry.io/5346538',
        release: process.env.SENTRY_RELEASE,
        integrations: [new Integrations.Vue({Vue, attachProps: true, logErrors: true})],
    });
}
