import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(Router)

const REDIRECTS = {
    tw: 'https://twitter.com/mconeeu',
    twitter: 'https://twitter.com/mconeeu',
    fb: 'https://www.facebook.com/mconeeu',
    facebook: 'https://www.facebook.com/mconeeu',
    ig: 'https://instagram.com/mconeeu',
    insta: 'https://instagram.com/mconeeu',
    yt: 'https://www.youtube.com/channel/UCG8upNAMiAchDxiFoe8dbaQ',
    youtube: 'https://www.youtube.com/channel/UCG8upNAMiAchDxiFoe8dbaQ'
}

const index = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/apply',
            name: 'Bewerben',
            component: () => import('../views/Apply.vue')
        },
        {
            path: '/team',
            name: 'Team',
            component: () => import('../views/Team.vue')
        },
        {
            path: '/story',
            name: 'Story',
            component: () => import('../views/Story.vue')
        },
        {
            path: '/rules',
            name: 'Regelwerk',
            component: () => import('../views/Rules.vue')
        },
        {
            path: '/regeln',
            redirect: '/rules'
        },
        {
            path: '/cape',
            name: 'Cape',
            component: () => import('../views/Cape.vue')
        },
        {
            path: '/imprint',
            name: 'Impressum',
            component: () => import('../views/Imprint.vue')
        },
        {
            path: '/privacy',
            name: 'Datenschutz',
            component: () => import('../views/Privacy.vue')
        }/*,
        {
            path: '/apply/designer',
            name: 'designer',
            component: () => import('./views/apply/Designer.vue')
        },
        {
            path: '/apply/content',
            name: 'content',
            component: () => import('./views/apply/Content.vue')
        },
        {
            path: '/apply/developer',
            name: 'developer',
            component: () => import('./views/apply/Developer.vue')
        },
        {
            path: '/apply/supporter',
            name: 'supporter',
            component: () => import('./views/apply/Supporter.vue')
        },
        {
            path: '/apply/builder',
            name: 'builder',
            component: () => import('./views/apply/Builder.vue')
        },
        {
            path: '/apply/supporter/Supporter',
            name: 'SupporterForm',
            component: () => import('./views/apply/supporter/Supporter.vue')
        },
        {
            path: '/apply/content/Event-Content',
            name: 'EventContentForm',
            component: () => import('./views/apply/content/Event-Content.vue')
        },
        {
            path: '/apply/content/server-content',
            name: 'ServerContentForm',
            component: () => import('./views/apply/content/Server-Content.vue')
        },
        {
            path: '/apply/designer/designer',
            name: 'designerForm',
            component: () => import('./views/apply/designer/Designer.vue')
        },
        {
            path: '/apply/builder/builder',
            name: 'builderForm',
            component: () => import('./views/apply/builder/Builder.vue')
        },
        {
            path: '/apply/developer/developer',
            name: 'developerForm',
            component: () => import('./views/apply/developer/Developer.vue')
        },
        {
            path: '/apply/developer/webdeveloper',
            name: 'webdeveloperForm',
            component: () => import('./views/apply/developer/Webdeveloper.vue')
        }*/,
        ...(Object.entries(REDIRECTS).map(([from, to]) => ({
            path: `/${from}`,
            beforeEnter() {
                window.location.replace(to)
            }
        }))),
        {
            path: '*',
            name: 'Not Found',
            component: () => import('../views/404.vue')
        }
    ],
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

index.beforeEach((to, from, next) => {
    document.title = to.name+' | MC ONE » Dein Nummer 1 Netzwerk'
    next()
})

export default index;
